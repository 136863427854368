import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import AllCategories from './AllCategories'
import AllTags from './AllTags'
import PropTypes from 'prop-types'

const styles = theme => ({
    theMenu:{
        //position: 'fixed',
        paddingTop: '45px',
    }
})

const RightMenu = ({ classes }) => {
    return (
        <section className={classes.theMenu}>
            <AllCategories />
            <AllTags />
        </section>
    )
}

RightMenu.propTypes = {
    classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(RightMenu)