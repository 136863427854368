import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import siteConfig from '../../../config/siteConfig'
import { Link } from 'gatsby'

const styles = theme => ({
    copyRight: {
        '& p': {
            fontSize: '0.8rem',
            paddingTop: '0rem',
            marginBottom: '0'
        },
        textAlign: 'center',
        paddingTop: theme.spacing.unit*4,
    },
    coffeeMoji: {

    },
    heartMoji: {

    },
    contactLink: {
        textDecoration: 'none',
        color: theme.palette.primary.main
    }
})
const Copyright = ({ classes }) => {
    return (
        <Typography component='div' className={classes.copyRight}>
            <p>
                {siteConfig.copyright}
            </p>
            <p>
                {' Made with lots of'} <span className={classes.coffeeMoji} role='img'>☕</span> {' and '} <span className={classes.heartMoji} role='img'>❤️</span>
            </p>
            <Link to='/contact'
                className={classes.contactLink}
            >
                Contact me
            </Link>
        </Typography>
    )
}

Copyright.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Copyright)