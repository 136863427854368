import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { StaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import withRoot from '../withRoot'
//import Header from './Header'
import RightMenu from './common/RightMenu'
import Hidden from '@material-ui/core/Hidden'
import Copyright from './common/Copyright'
import MyInfo from './myInfo'

const styles = theme => ({
    root: {
        marginTop: theme.spacing.unit * 4,
        //paddingLeft: theme.spacing.unit * 2,
        //paddingRight: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
        [theme.breakpoints.up('md')]: {
            paddingLeft: theme.spacing.unit * 3,
            paddingRight: theme.spacing.unit * 3
        },
        flexGrow: 1,
        maxWidth: '1400px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    blogPaper: {
        // [theme.breakpoints.up('md')]: {
        //     marginTop: theme.spacing.unit * 5,
        // }
    },
    rightMenu: {
        marginTop: theme.spacing.unit * 5,
        //marginLeft: theme.spacing.unit * 4
    },
    leftMenuContainer: {
        [theme.breakpoints.up('md')]: {
            //flexBasis: '80px',
            //maxWidth: '80px',
        }
    },
    leftMenu: {
        //marginTop: theme.spacing.unit * 5,
    }
})

const Layout = ({ children, classes, isPage = false }) => (
    <StaticQuery
        query={graphql`
            query SiteTitleQuery {
                site {
                    siteMetadata {
                        title
                    }
                }
            }
        `}
        render={data => (
            <Fragment>
                {/* <Header siteTitle={data.site.siteMetadata.title} /> */}
                <MyInfo siteMetadata={data.site.siteMetadata} />
                <section className={classes.root}>
                    <Grid
                        container={true}
                        spacing={0}
                    >
                        {isPage?null:
                            <Grid item={true} xs={12} sm={12} md={1} lg={1} className={classes.leftMenuContainer} component='section' >
                                <div id='social-share' className={classes.leftMenu} />
                            </Grid>
                        }
                        <Grid item={true} xs={12} sm={isPage?12:12} md={isPage?12:9} lg={isPage?12:8} className={classes.blogPaper} component='section'>
                            {/* {isPage?children:
                                <Paper
                                    square={false}
                                    elevation={8}
                                >
                                    {children}
                                </Paper>} */}
                            {children}
                            <Copyright />
                        </Grid>
                        {isPage?null:
                            <Hidden only={['xs', 'sm']}>
                                <Grid item={true} xs={false} sm={false} md={2} lg={2} className={classes.rightMenu} component='aside'>
                                    <RightMenu />
                                </Grid>
                            </Hidden>}
                    </Grid>
                </section>
            </Fragment>
        )}
    />
)

Layout.defaultProps = {
    isPage: false
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    classes: PropTypes.object.isRequired,
    isPage: PropTypes.bool.isRequired,
}

export default withRoot(withStyles(styles)(Layout))
