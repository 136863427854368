module.exports = {
    siteTitle: 'stanilov\'s blog',
    siteTitle1: 'stan',
    siteTitle2: 'codes()',
    siteTitleShort: 'stan',
    siteLogo: '/images/site-icon.png', // Logo used for SEO and manifest.
    siteUrl: 'https://astanilov.com', // Domain of your website without pathPrefix.
    siteName: 'Stanilov\'s blog',
    pathPrefix: '/stan-blog', // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
    siteDescription: 'Blog of Atanas Stanilov - web development, javascript, react', // Website description used for RSS feeds/meta description tag.
    siteRss: '/rss.xml', // Path to the RSS file.
    siteFBAppID: '1825356251115265', // FB Application ID for using app insights
    googleAnalyticsID: 'UA-169882012-1', // GA tracking ID.
    googleOptimizeTrackingId: '',
    googleTagManagerId: 'GTM-54J32J5',
    gtmAuth: '',
    gtmPreview: '', 
    disqusShortname: 'astanilov', // Disqus shortname.
    postDefaultCategoryID: 'JavaScript', // Default category for posts.
    dateFromFormat: 'YYYY-MM-DD', // Date format used in the frontmatter.
    dateFormat: 'DD MM YYYY', // Date format for display.
    userName: 'Atanas Stanilov', // Username to display in the author segment.
    userTwitter: 'AtanasStanilov', // Optionally renders "Follow Me" in the UserInfo segment.
    userLocation: 'Plovdiv, Bulgaria', // User location to display in the author segment.
    userAvatar: '', // User avatar to display in the author segment.
    userDescription: '', // User description to display in the author segment.
    // Links to social medias
    userLinks: [
        {
            label: 'GitHub',
            url: 'https://github.com/StanCodes',
        },
        {
            label: 'LinkedIn',
            url: 'https://www.linkedin.com/in/atanas-stanilov/',
        },
        {
            label: 'Twitter',
            url: 'https://twitter.com/AtanasStanilov',
        },
        {
            label: 'Email',
            url: 'mailto:atanasstanilov@gmail.com',
        },
        {
            label: 'Facebook',
            url: 'https://facebook.com/a.stanilov',
        }
    ],
    copyright: 'Copyright © 2021, Atanas Stanilov', // Copyright string for the footer of the website and RSS feed.
    themeColor: '#c62828', // Used for setting manifest and progress theme colors.
    backgroundColor: '#e0e0e0', // Used for setting manifest background color.
    defaultPostsCount: 10,
}