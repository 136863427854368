import React from 'react'
import { Link } from 'gatsby'
import { StaticQuery, graphql } from 'gatsby'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import siteConfig from '../../config/siteConfig'


const styles = theme => ({
    myInfo: {
        display: 'flex',
        padding: '0px 30px',
        marginTop: '30px',
        alignItems: 'center',
        [theme.breakpoints.up('sm')]: { 
            padding: '0px 36px',
        },
        [theme.breakpoints.up('md')]: { 
            padding: '0px 126px',
        },
        maxWidth: '1400px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    imageLink: {
        flexShrink: 0,
        borderRadius: '50%',
        height: '100px',
        width: '100px',
        position: 'relative',
        overflow: 'hidden',
        shapeOutside: 'circle()'
    },
    myImage: {
        borderRadius: '50%',
        //maxHeight: '100px',
        //maxWidth: '100px'
        position: 'absolute',
        left: '50%',
        top: '50%',
        width: '100%',
        height: 'auto',
        transform: 'translate(-50%, -50%)'
    },
    infoText: {
        marginLeft: '10px'
    },
    theTitle: {
        fontSize: '1.25rem',
        lineHeight: '1.6',
        fontWeight: '500'
    },
    theDescr: {
        marginLeft: '10px'
    }
})

const MyInfo = ({classes, siteMetadata }) => {
    return(
        <StaticQuery 
            query = {graphql`
                query MyImageQuery {
                    myImage: file(relativePath: {eq: "its_a_me.jpg"}) {
                        childImageSharp {
                            fluid(quality: 50) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    },
                    myIcon: file(relativePath: {eq: "site-icon.jpg"}) {
                        childImageSharp {
                            fixed(quality: 100, width: 200, height: 200) {
                                ...GatsbyImageSharpFixed
                            }
                        }
                    }
                }
            `}
            render = {data => (
                <React.Fragment>
                    <Helmet
                        titleTemplate={`%s | ${siteMetadata.title}`}
                        defaultTitle={siteMetadata.title}
                        meta={[
                            { name: 'description', content: 'Web development blog of Atanas Stanilov' },
                            { name: 'keywords', content: 'blog, javascript, js, react, reactjs, node, web, developer, html, css' },
                            { name: 'author', content: `${siteConfig.userName}` },
                            { name: 'twitter:site', content: '@astanilov' },
                            { property: 'og:type', content: 'website' },
                            { property: 'og:site_name', content: `${siteConfig.siteName}` },
                            { property: 'og:title', content: `${siteMetadata.title}` },
                            { property: 'og:url', content: `${siteConfig.siteUrl}` },
                            { property: 'og:image', content: `${siteConfig.siteUrl + data.myIcon.childImageSharp.fixed.src}` },
                            { property: 'og:image:type', content: 'image/jpeg' },
                            { property: 'og:image:width', content: '200' },
                            { property: 'og:image:height', content: '200' },
                            { property: 'og:image:alt', content: 'A.Stanilov\'s blog' },
                            { property: 'og:description', content: 'Web development blog of Atanas Stanilov' },
                        ]}
                        htmlAttributes={{ lang: 'en'}}
                        // link={[
                        //     { rel: 'canonical', href: `${siteConfig.siteUrl}` }
                        // ]}
                    >
                        <html lang='en' />
                    </Helmet>
                    <section className={classes.myInfo}>
                        <Link to='/'
                            className={classes.imageLink}
                        >
                            <img src={data.myImage.childImageSharp.fluid.src} 
                                title='Me' 
                                alt='A picture of myself' 
                                className={classes.myImage}
                            />
                        </Link>
                        <div className={classes.infoText}>
                            <Typography variant='h1' className={classes.theTitle}>
                                Atanas Stanilov
                            </Typography>
                            <Typography variant='body2' className={classes.theDescr}>
                                Part-time web developer. Full-time superhero.
                            </Typography>
                        </div>
                    </section>
                </React.Fragment>
            )}
        />
    )
}

MyInfo.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(MyInfo)