import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import kebabCase from 'lodash/kebabCase'
import { Link } from 'gatsby'

const styles = theme => ({
    postCats: {
        textDecoration: 'none',
        textTransform: 'uppercase',
        display: 'inline',
        color: '#A5A5A8'
    },
    postCatsRow: {
        textDecoration: 'none',
        textTransform: 'uppercase',
        display: 'inline',
        color: theme.palette.secondary.main
    },
    postCat: {
        textDecoration: 'none',
        color: theme.palette.secondary.main
    },
    postCatRow: {
        textDecoration: 'none',
        color: theme.palette.secondary.main
    },
    theCatBlock: {
        //padding: theme.spacing.unit*0.25,
        fontSize: '0.9rem'
    },
    postCatsUL: {
        marginTop: '0rem',
        padding: 0,
        listStyle: 'none',
        /*'& li': {
            '&::before': {
                content: '">"',
                fontFamily: ['Nanum Gothic Coding', 'monospace'],
                display: 'inline',
                marginRight: '0.5rem',
                width: '1.5rem'
            },
            '&:hover': {
                '&::before': {
                    content: '"-"',
                }
            }
        }*/
    }
})

const PostCategories = ({cats, row=false, hasIn=false, classes}) => {
    let categoriesJSX = () => {
        let ret = []
        cats.map((cat, ndx) => {
            if(row){
                //title
                ret[ndx] = 
                    <span key={`row-${cat}`}>
                        {ndx == 0 ? <span>{hasIn?'IN':''}: </span> : ''}
                        <Link to={`/categories/${kebabCase(cat)}/`} className={classes.postCat}>
                            <span>{cat}</span>
                        </Link>
                        {(cats.length > 1 && ndx != cats.length-1) ? <span>, </span> : ''}
                    </span>
            } else {
                //right menu
                let catt = cat.fieldValue
                ret[ndx] =
                    <li key={catt} className={classes.theCatBlock}>
                        <Link to={`/categories/${kebabCase(catt)}/`} className={classes.postCatRow}>
                            <span>{catt}{cat.totalCount?`(${cat.totalCount})`:null}</span>
                        </Link>
                    </li>
            }
        })

        if(!row) {
            return <ul className={classes.postCatsUL}>{ret}</ul>
        }

        return ret
    }

    return (
        <Typography component='span' variant='h6' className={row?classes.postCats:classes.postCatsRow}>
            {categoriesJSX()}
        </Typography>
    )
}

PostCategories.defaultProps = {
    hasIn: false,
    row: false
}

PostCategories.propTypes = {
    cats: PropTypes.array.isRequired,
    row: PropTypes.bool.isRequired,
    hasIn: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(PostCategories)