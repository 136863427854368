import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import kebabCase from 'lodash/kebabCase'
import { Link } from 'gatsby'

const styles = theme => ({
    postTags: {
        textDecoration: 'none',
        textTransform: 'lowercase',
        paddingLeft: theme.spacing.unit*0.25,
        paddingRight: theme.spacing.unit*0.25,
        paddingBottom: theme.spacing.unit
    },
    theTag: {
        paddingRight: theme.spacing.unit*0.75,
        paddingTop: theme.spacing.unit,
        display: 'inline-block'
    },
    theTagBlock: {
        display: 'block',
        padding: theme.spacing.unit*0.25
    },
    tagLink: {
        textDecoration: 'none',
        color: theme.palette.primary.main,
    }
})

const PostTags = ({tags, row, classes}) => (
    <Typography component='span' className={classes.postTags}>
        {tags.map((tag, ndx) => {
            return (
                <span key={tag.fieldValue?tag.fieldValue:tag} className={row?classes.theTag:classes.theTagBlock}>
                    <Link to={`/tags/${kebabCase(tag.fieldValue?tag.fieldValue:tag)}/`} className={classes.tagLink}>
                        <span>#{tag.fieldValue?tag.fieldValue:tag}{tag.totalCount?`(${tag.totalCount})`:null}</span>
                    </Link>
                    {row?((tags.length > 1 && ndx != tags.length-1) ? ' ' : ''):''}
                </span>
            )
        })}
    </Typography>
)

PostTags.propTypes = {
    tags: PropTypes.array.isRequired,
    row: PropTypes.bool,
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(PostTags)