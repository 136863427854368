import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { StaticQuery, graphql } from 'gatsby'
import PostCategories from './PostCategories'
import PropTypes from 'prop-types'

const styles = theme => ({
    Categories: {
        display: 'block',
    },
    Cats: {

    }
})

const AllCategories = ({ classes }) => {

    return (
        <StaticQuery 
            query = {graphql`
                query AllCategories {
                    allMarkdownRemark(
                        limit: 2000
                    ) {
                        group(field: frontmatter___categories) {
                            fieldValue
                            totalCount
                        }
                    }
                }
            `}
            render = {data => (
                <section className={classes.Categories}>
                    <Typography variant='h5' className={classes.Cats}>
                        categories:
                    </Typography>
                    <PostCategories cats={data.allMarkdownRemark.group} />
                </section>
            )}
        />
    )
}

AllCategories.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(AllCategories)