import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import PostTags from './PostTags'
import { Typography } from '@material-ui/core'
import { StaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'

const styles = theme => ({
    Tags: {
        display: 'block',
        marginTop: theme.spacing.unit*2,
        '& span': {
            lineHeight: 'normal',
            paddingTop: 0,
        }
    },
    Hashtags: {

    }
})

const AllTags = ({ classes }) => {

    return (
        <StaticQuery 
            query = {graphql`
                query AllTags {
                    allMarkdownRemark(
                        limit: 2000
                    ) {
                        group(field: frontmatter___tags) {
                            fieldValue
                            totalCount
                        }
                    }
                }
            `}
            render = {data => (
                <section className={classes.Tags}>
                    <Typography variant='h5' className={classes.Hashtags}>
                        hashtags:
                    </Typography>
                    <PostTags tags={data.allMarkdownRemark.group} row={true} />
                </section>
            )}
        />
    )
}

AllTags.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(AllTags)